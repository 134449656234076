import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./NewAccountSelectDrop.css";
import { capitalizeAllWords, trimLongString } from "../../utils/helper/Helper";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";

const NewAccountSelectDrop = ({ onSelect, selected, position }) => {
  const { all_account } = useSelector((state) => state.user);
  const [showDrop, setShowDrop] = useState(false);

  const dropIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        stroke="#EA872D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 6.5l-4 4-4-4"
      ></path>
    </svg>
  );

  const testingPreviewRef = useOnClickOutside(() => {
    setShowDrop(false);
  });

  return (
    <div
      ref={testingPreviewRef}
      className="new-account-drop-index-wrapper-source-account"
    >
      {/* value selected start */}
      <div
        onClick={() => {
          setShowDrop(!showDrop);
        }}
        className="value-selected-box grey-bg-two"
        style={{ cursor: all_account?.length > 1 ? "pointer" : "default" }}
      >
        <p className="value-select">
          {trimLongString(
            capitalizeAllWords(selected?.custom_account_name),
            20
          ) || ""}
        </p>
        {all_account?.length > 1 && (
          <div className="icon-box">
            <figure className="img-box">{dropIcon}</figure>
          </div>
        )}
      </div>
      {/* value selected end */}
      {all_account?.length > 1 && (
        <>
          {showDrop && (
            <div
              className={`drop-box-wrapper ${
                position === "bottom" ? "drop-box-wrapper-bottom" : ""
              } shadow-shadow-box-shadow  card-bg`}
            >
              {all_account?.map((chi, idx) => {
                return (
                  <div
                    key={idx}
                    className={`item-row ${
                      idx + 1 !== all_account?.length && "border-theme-bottom"
                    } ${
                      selected?.id === chi?.id && "item-row-selected grey-bg"
                    }`}
                    onClick={() => {
                      if (selected?.id !== chi?.id) {
                        onSelect && onSelect(chi);
                        setShowDrop(false);
                      }
                    }}
                  >
                    <p className="name">
                      {trimLongString(chi?.custom_account_name, 25) || ""}
                    </p>
                    <p className="text grey-white-color">{`${
                      chi?.account_number || ""
                    } • ${RavenNumberFormat(chi?.balance)}`}</p>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NewAccountSelectDrop;
