import { RavenModal, RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import "../../styles/fragments/NewAccountsDropDownModal.css";
import ActionContext from "../../context/ActionContext";
import { useSelector } from "react-redux";
import { trimLongString } from "../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NewAccountsDropDownModal = ({ visble, onClose, big, onSelect }) => {
  const actionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const { all_account, user_resource_and_permissions } = useSelector(
    (state) => state.user
  );

  const copyIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        fill="#1A1A1A"
        d="M6.4 2.9a.8.8 0 01.8-.8h1.6a.8.8 0 010 1.6H7.2a.8.8 0 01-.8-.8z"
      ></path>
      <path
        fill="#1A1A1A"
        d="M4.8 2.9a1.6 1.6 0 00-1.6 1.6v8.8a1.6 1.6 0 001.6 1.6h6.4a1.6 1.6 0 001.6-1.6V4.5a1.6 1.6 0 00-1.6-1.6 2.4 2.4 0 01-2.4 2.4H7.2a2.4 2.4 0 01-2.4-2.4z"
      ></path>
    </svg>
  );

  const [isCopied, setIsCopied] = useState("");

  const handleCopied = async (param, copy) => {
    setIsCopied(param);
    await navigator.clipboard.writeText(param);  
    setTimeout(() => {
      setIsCopied("");
    }, 1000);
  };

  const addIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      className="img"
    >
      <path
        // fill="#000"
        className="black-white-color"
        fillRule="evenodd"
        d="M8 4.3a1 1 0 011 1v2.2h2.2a1 1 0 110 2H9v2.2a1 1 0 11-2 0V9.5H4.8a1 1 0 010-2H7V5.3a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return (
    <>
      <RavenModal
        className={`new-account-drop-down-index-wrap-subs-wrap`}
        onClose={onClose}
        visble={visble}
      >
        {" "}
        {/* add new account start */}
        {actionCtx?.showChangeAccountBtn &&
          user_resource_and_permissions?.allowed_permissions?.includes(
            "can_create_account"
          ) && (
            <div onClick={() => {
                navigate("/dashboard-sub-account-add");
                actionCtx.setCurrentAccountDrop(false);
                actionCtx.setShowChangeAccountBtn(false);
              }} className="add-new-account grey-bg">
              <div className="img-icon">
                <figure className="img-box">{addIcon}</figure>
              </div>
              <p className="text">Add New Account</p>
            </div>
          )}
        {/* add new account end */}
        <div
        //   onClick={() => {
        //     console.log(all_account);
        //   }}
          className="new-account-drop-down-index-wrap-subs border-theme-top"
        >
          {all_account?.map((chi, idx) => {
            return (
              <div
                key={idx}
                className={`item-row ${
                  idx !== all_account?.length - 1 && "border-theme-bottom"
                }`}
                onClick={() => {
                    
                  onSelect && onSelect(chi);
                }}
              >
                <div className="bank-details">
                  <div className="account-name">
                    {chi?.custom_account_name || ""}

                    {/* chaeck active start */}
                    {actionCtx?.currentAccount?.custom_account_name ===
                      chi?.custom_account_name && (
                      <>
                        <div className="check-box shadow-shadow-box-shadow  grey-bg">
                          <FaCheck className="icon" />
                        </div>
                      </>
                    )}
                    {/* chaeck active end */}
                  </div>
                  <p className="bank-name grey-white-color">
                    {`${chi?.account_number} • ${trimLongString(
                      chi?.bank,
                      30
                    )}`}
                  </p>
                </div>
                {/* bank details end */}
                {chi?.primary === 1 && big && (
                  <>
                    <div className="primary-box grey-bg-two">
                      <p className="text">Primary</p>
                    </div>
                  </>
                )}
                <div className="account-copy-details">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopied(chi?.account_number);
                    }}
                    className="copy-box grey-bg"
                  >
                    <p className="text">
                      {isCopied === chi?.account_number ? `Copied` : `Copy`}
                    </p>
                    <div className="copy-icon">
                      {isCopied === chi?.account_number ? (
                        <div className="icon-box black-white-bg">
                          <FaCheck className="icon" />
                        </div>
                      ) : (
                        <figure className="img-box">{copyIcon}</figure>
                      )}
                    </div>
                  </div>
                  <p className="balance grey-white-color">{`Bal: ${RavenNumberFormat(
                    chi?.balance
                  )}`}</p>
                </div>
              </div>
            );
          })}
        </div>
      </RavenModal>
    </>
  );
};

export default NewAccountsDropDownModal;
